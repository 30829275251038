import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { finalize } from 'rxjs/operators';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor(private _spinner: NgxSpinnerService, private _utility:UtilityService) { }

  activeReq: number = 0;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.activeReq) {
      this._spinner.show();
      this._utility.toggleSpinner(true)
    }

    this.activeReq++;

    return next.handle(request).pipe(
      finalize(() => {
        this.activeReq--;
        if (!this.activeReq) {
          this._spinner.hide();
          this._utility.toggleSpinner(false)
        }
      })
    )
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  public routeName: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public spinnerStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public formResetStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // routeName: string = null;

  constructor(private _route: Router) {
    this.setCurrentRoute()
  }

  redirect(route: string): void {
    this._route.navigate([route]);
  }

  setCurrentRoute() {
    this._route.events.subscribe(evt => {
      //@ts-ignore
      if (evt.url) {
        // @ts-ignore
        let name = (evt.url.replace('/admin/', '')).split('/')[0]
        this.routeName.next(name)
        //@ts-ignore
        // this.routeName.next(evt.url.replace('/admin/', '').replace(/[^a-z]/i, ' '))
      }
    });
  }

  // update status of the loader
  toggleSpinner(status: boolean): void {
    this.spinnerStatus.next(status);
  }

  resetForm(status: boolean): void {
    this.formResetStatus.next(status);
  }
}

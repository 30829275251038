import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { tap, catchError } from 'rxjs/operators';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { TokenService } from 'src/app/services/auth/token/token.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(
    private _token: TokenService,
    private _toast: ToastrService,
    private _utility: UtilityService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // return next.handle(request);
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && ['PUT', 'POST', 'DELETE', 'PATCH'].includes(request.method)) {
          if (event.body.status === false) {
              this._toast.success(event.body.message);
          }
          else {
            // event.body.status ?
            this._toast.success(event.body.message) 
            // : this._toast.error(event.body.message) ;         
            this._utility.resetForm(true)
          }
        }
      }), catchError((err: HttpErrorResponse) => {
        let srrMsg = err.status ? err.error.message : 'Unable to connect with server. Please contact to Admin!'
        
        this._toast.error(srrMsg);

        if (err.status === 401) {
          this._token.logout();
          this._utility.redirect('/auth/login');
        }
        return throwError(err.error || err.statusText);
      })
    )
  }
}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/services/auth/token/token.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private _token: TokenService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = {};

    // // check if user already login
    if (this._token.islogin() && this._token.isTokenValid()) {
      headers = { 'x-access-token': this._token.getToken() };
    }

    return next.handle(request.clone({
      setHeaders: headers
    }));
  }
}
